import { Menu } from "@mui/icons-material";
import { Box, Button, Drawer, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";

interface MenuBarLargeProps {
  menuItems: { type: "menuItem" | "button"; id: string; title: string }[];
  scrollToSection: (sectionId: string) => void;
}

export const MenubarLarge: React.FC<MenuBarLargeProps> = ({
  menuItems,
  scrollToSection,
}): JSX.Element => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ml: "-18px",
        px: 0,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", md: "flex", gap: 5, marginRight: 10 },
        }}
      >
        {menuItems.map((item) => {
          return item.type === "menuItem" ? (
            <MenuItem
              key={item.id}
              onClick={() => scrollToSection(item.id)}
              sx={{
                paddingTop: "6px",
                paddingBottom: "6px",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <Typography variant="body2" color="text.primary">
                {item.title}
              </Typography>
            </MenuItem>
          ) : (
            <Button
              key={item.id}
              color="primary"
              variant="contained"
              size="small"
              component="a"
              onClick={() => scrollToSection(item.id)}
              target="_blank"
            >
              {item.title}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
