import { Box, alpha } from "@mui/material";
import React from "react";
import { BannerHeader } from "../banner/BannerHeader";
import { WhoWeAre } from "./WhoWeAre";

export const Introduction: React.FC = ({}): JSX.Element => {
  return (
    <>
      <Box
        id="whoweare"
        sx={{
          width: "100%",
          backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",
        }}
      >
        <BannerHeader />
        <WhoWeAre />
      </Box>
    </>
  );
};
