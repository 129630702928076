import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { Copyrights } from "./Copyrights";
import { footerItems, scrollToView } from "../common/Data";

const logoStyle = {
  width: "140px",
  height: "auto",
};

export default function Footer() {
  const scrollToSection = React.useCallback(scrollToView, []);

  return (
    <Container
      id="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <Box sx={{ ml: "-15px", marginLeft: "1px" }}>
              <img
                src="/static/images/saluq-logo.png"
                style={logoStyle}
                alt="logo of sitemark"
              />
            </Box>
            Contact us: <Link href="mailto:info@saluq.com">info@saluq.com</Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Product
          </Typography>
          {footerItems.map((item) => {
            return (
              <Link
                sx={{ cursor: "pointer" }}
                key={item.id}
                color="text.secondary"
                onClick={() => scrollToSection(item.id)}
              >
                {item.title}
              </Link>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Copyrights />
      </Box>
    </Container>
  );
}
