import {
  Check,
  EngineeringRounded,
  TransformSharp,
  Web,
} from "@mui/icons-material";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

const items = [
  {
    icon: <ViewQuiltRoundedIcon sx={{ fontSize: 100, color: "lightblue" }} />,
    title: "GenAI",
    description:
      "From concept to deployment, we craft tailored AI solutions utilizing state-of-the-art technologies. Seamlessly integrate AI technologies into your existing systems and workflows for enhanced efficiency and performance.",
  },
  {
    icon: <EngineeringRounded sx={{ fontSize: 100, color: "lightblue" }} />,
    title: "CAD and Engineering Applications",
    description:
      "CAD application development is a fundamental offering at Saluq. Our team boasts expertise in the latest CAD technologies, enabling us to meet the specific requirements of this domain with precision and excellence.",
  },
  {
    icon: <TransformSharp sx={{ fontSize: 100, color: "lightblue" }} />,
    title: "Digital Twin Services",
    description:
      "Reality modelling, retrofit study, 3D modelling, and retrofit studies, along with 4D construction simulation and animation, complemented by intelligent data and document digitization.",
  },
  {
    icon: <Web sx={{ fontSize: 100, color: "lightblue" }} />,
    title: "Web Applications",
    description:
      "Create highly scalable web solutions tailored to meet the evolving needs of your business, ensuring seamless performance and adaptability to accommodate growth.",
  },
  {
    icon: <DevicesRoundedIcon sx={{ fontSize: 100, color: "lightblue" }} />,
    title: "Cloud and DevOps",
    description:
      "Modernize your cloud services application and infrastructure to align with contemporary standards and leverage the latest advancements in technology for enhanced performance, security, and efficiency.",
  },
  // {
  //   icon: <DesignServices sx={{ fontSize: 100, color: "lightblue" }} />,
  //   title: "Design, UX",
  //   description:
  //     "From intuitive layouts to responsive design, we prioritize seamless user experiences that enhance every interaction. Explore our services and discover a world where design meets functionality for unparalleled ease and satisfaction.",
  // },
  {
    icon: <Check sx={{ fontSize: 100, color: "lightblue" }} />,
    title: "Quality Assurance",
    description:
      "Experience peace of mind with our rigorous quality assurance processes, ensuring every deliverable meets the highest standards of excellence. Trust in our commitment to quality for reliable, top-notch results every time.",
  },
];

export const Services = () => {
  return (
    <Box
      id="services"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "80%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Services
          </Typography>
          <Typography
            textAlign="justify"
            color="text.secondary"
            sx={{
              alignSelf: "center",
              width: { sm: "100%", md: "100%" },
              pt: "2%",
            }}
          >
            Empowering Action, Driving Results! With our innovative approach, we
            specialize in creating cutting-edge applications through our
            software related services.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                key={index}
                variant="outlined"
                component={Button}
                sx={{
                  p: 3,
                  height: "fit-content",
                  minHeight: "300px",
                  width: "100%",
                  background: "none",
                }}
              >
                <Stack>
                  <Box>{item.icon}</Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                      textAlign="justify"
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      textAlign="justify"
                      sx={{ my: 0.5 }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
