import { Box, Divider, Link, Typography } from "@mui/material";
import * as React from "react";
import { Introduction } from "./components/introduction/Introduction";
import { Menubar } from "./components/menu/Menubar";
import Footer from "./components/footer/Footer";
import { Highlights } from "./components/highlights/Highlights";
import { Services } from "./components/services/Services";
import { Clients } from "./components/clients/Clients";

export default function App() {
  return (
    <Box>
      <Menubar />
      <Introduction />
      <Divider />
      <Services />
      <Divider />
      <Clients />
      <Divider />
      <Highlights />
      <Divider />
      <Footer />
    </Box>
  );
}
