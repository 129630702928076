import { AppBar, Container, Toolbar } from "@mui/material";
import * as React from "react";
import { MenubarCompact } from "./MenubarCompact";
import { MenubarLarge } from "./MenubarLarge";
import { menuItems, scrollToView } from "../common/Data";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
  margin: "10px",
};

export const Menubar = () => {
  const scrollToSection = React.useCallback(scrollToView, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        marginTop: 2,
      }}
    >
      <Container id="home">
        <Toolbar
          variant="regular"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            borderRadius: "999px",
            bgcolor: "rgba(255, 255, 255, 0.4)",
            backdropFilter: "blur(24px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",
            boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
          }}
        >
          <img
            src="/static/images/saluq-logo.png"
            style={logoStyle}
            alt="logo of Saluq"
            onClick={() => scrollToSection("home")}
          />
          <MenubarLarge
            menuItems={menuItems}
            scrollToSection={scrollToSection}
          />
          <MenubarCompact
            menuItems={menuItems}
            scrollToSection={scrollToSection}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
