import { Box, Container, Grid, Typography } from "@mui/material";
import * as React from "react";

const clientLogos = [
  "/static/images/clients/almazroui.svg",
  "/static/images/clients/browncaldwell.svg",
  "/static/images/clients/techmanistan.svg",
  "/static/images/clients/woodera.png",
  "/static/images/clients/map.svg",
];

const logoStyle = {
  width: "100px",
  height: "80px",
  margin: "0 32px",
  opacity: 0.7,
};

export const Clients = () => {
  return (
    <Container id="clients">
      <Box id="logoCollection" sx={{ py: 4, my: 8 }}>
        <Typography
          component="p"
          variant="subtitle2"
          align="center"
          color="text.secondary"
        >
          Trusted by the best companies
        </Typography>
        <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.6 }}>
          {clientLogos.map((logo, index) => (
            <Grid item key={index}>
              <img src={logo} alt={`Company ${index + 1}`} style={logoStyle} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
