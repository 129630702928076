import { Box, Container, Stack, Typography, alpha } from "@mui/material";
import React from "react";
import { BannerHeader } from "../banner/BannerHeader";

export const WhoWeAre: React.FC = ({}): JSX.Element => {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Stack spacing={1} useFlexGap sx={{ width: { xs: "100%", sm: "100%" } }}>
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignSelf: "center",
            textAlign: "center",
            fontSize: "clamp(3.5rem, 10vw, 4rem)",
          }}
        >
          Who we &nbsp;
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: "clamp(3rem, 10vw, 4rem)",
              color: "primary.main",
            }}
          >
            Are?
          </Typography>
        </Typography>
        <Typography
          textAlign="justify"
          color="text.secondary"
          sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
        >
          Saluq is an outsourcing firm that collaborates with premier software
          enterprises worldwide to provide top-tier commercial software
          solutions to its clientele.
          <br />
          <br />
          Leveraging extensive experience working with prominent corporations
          across diverse industries, Saluq possesses an intricate understanding
          of industry-specific demands. Renowned for its consistent delivery of
          high-quality software solutions, Saluq stands as an exemplary offshore
          partner for your software endeavors.
        </Typography>
      </Stack>
    </Container>
  );
};
