import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: "Cutting-Edge Solutions",
    description:
      "We craft bespoke strategies and implement cutting-edge technologies to address your unique challenges and capitalize on emerging opportunities.",
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: "Proven Track Record of Excellence",
    description:
      "With a rich tapestry of successful projects woven across diverse industries and geographies, we've earned the unwavering trust of clients worldwide.",
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: "Rigorous Quality Assurance",
    description:
      "Quality isn't just a standard; it's a relentless pursuit of perfection. We adhere to stringent quality assurance protocols at every stage of the project.",
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: "Cost-Effective Solutions",
    description:
      "In today's hyper-competitive landscape, we understand the paramount importance of cost-effectiveness. We offer competitively priced solutions without ever compromising on quality.",
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: "Seamless Integration, Seamless Success",
    description:
      " We collaborate closely, communicate transparently, and adapt swiftly to your evolving needs, ensuring a seamless and symbiotic partnership that drives mutual success.",
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: "Client-Centric Approach",
    description:
      "Your satisfaction is our top priority. We listen to your needs, communicate transparently, and collaborate closely to ensure we exceed your expectations.",
  },
];

export const Highlights = () => {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "80%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography
            textAlign="justify"
            color="text.secondary"
            sx={{
              alignSelf: "center",
              width: { sm: "100%", md: "100%" },
              pt: "2%",
            }}
          >
            We redefine possibilities through our exceptional off-shore and
            engineering services. Embark on a journey with us as we navigate the
            vast ocean of opportunities, leveraging our extensive expertise to
            propel your business to new heights of success.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">{item.description}</Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
