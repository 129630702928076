import { Link, Typography } from "@mui/material";
import React from "react";

export const Copyrights = () => {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="http://saluq.com">Saluq</Link>&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
};
