import { Menu } from "@mui/icons-material";
import { Box, Button, Drawer, MenuItem } from "@mui/material";
import React, { useState } from "react";

interface MenuBarCompactProps {
  menuItems: { type: "menuItem" | "button"; id: string; title: string }[];
  scrollToSection: (sectionId: string) => void;
}

export const MenubarCompact: React.FC<MenuBarCompactProps> = ({
  menuItems,
  scrollToSection,
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const handleScrollToSection = (id: string) => {
    scrollToSection(id);
    setOpen(false);
  };

  return (
    <Box sx={{ display: { sm: "", md: "none" } }}>
      <Button
        variant="text"
        color="primary"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        sx={{ minWidth: "30px", p: "4px" }}
      >
        <Menu />
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            minWidth: "60dvw",
            p: 2,
            backgroundColor: "background.paper",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              flexGrow: 1,
            }}
          >
            {menuItems.map((item) => {
              return item.type === "menuItem" ? (
                <MenuItem
                  onClick={() => handleScrollToSection(item.id)}
                  key={item.id}
                >
                  {item.title}
                </MenuItem>
              ) : (
                <Button
                  key={item.id}
                  color="primary"
                  variant="contained"
                  component="a"
                  onClick={() => handleScrollToSection(item.id)}
                  sx={{ width: "70%" }}
                >
                  {item.title}
                </Button>
              );
            })}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};
