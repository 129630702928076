export const menuItems: {
  type: "menuItem" | "button";
  id: string;
  title: string;
}[] = [
  {
    type: "menuItem",
    id: "whoweare",
    title: "Who we Are",
  },
  {
    type: "menuItem",
    id: "services",
    title: "Services",
  },
  {
    type: "menuItem",
    id: "clients",
    title: "Clients",
  },
  {
    type: "menuItem",
    id: "highlights",
    title: "Highlights",
  },
  {
    type: "button",
    id: "footer",
    title: "Contact Us",
  },
];

export const footerItems: { id: string; title: string }[] = [
  {
    id: "whoweare",
    title: "About us",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "clients",
    title: "Clients",
  },
  {
    id: "highlights",
    title: "Highlights",
  },
];

export const scrollToView = (sectionId: string) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });
  }
};
