import { Box, Container, Typography, alpha } from "@mui/material";
import React from "react";
import ProTip from "../../ProTip";

export const BannerHeader: React.FC = ({}): JSX.Element => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "column" },
        alignItems: "center",
        pt: { xs: 4, sm: 8 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <Box
        id="image"
        sx={{
          mt: { xs: 10, sm: 10 },
          height: { xs: 300, sm: 500 },
          width: "100%",
          backgroundImage: 'url("/static/images/saluq-banner.png")',
          alignContent: "center",
          backgroundSize: "100% 100%",
          borderRadius: "10px",
          outline: "1px solid",
          outlineColor: alpha("#BFCCD9", 0.5),
          boxShadow: `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`,
        }}
      >
        <ProTip />
      </Box>
    </Container>
  );
};
